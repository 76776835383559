import React from "react";

const sizes = {
  xs: "text-[10px] font-normal leading-[160%]",
  lg: "text-xl font-medium leading-[160%]",
  s: "text-xs font-medium leading-[160%]",
  md: "text-[13px] font-medium",
};

const Text = ({ children, className = "", as, size = "s", ...restProps }) => {
  const Component = as || "p";

  return (
    <Component className={`text-white-A700_b7 font-inter ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };
