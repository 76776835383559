import React from "react";

const sizes = {
  "2xl": "text-7xl font-bold leading-[160%]",
  xl: "text-5xl font-bold leading-[140%]",
  s: "text-xl font-bold leading-[160%]",
  md: "text-[28px] font-semibold leading-[160%]",
  xs: "text-[11px] font-semibold leading-[160%]",
  lg: "text-[38px] font-bold",
};

const Heading = ({ children, className = "", size = "s", as, ...restProps }) => {
  const Component = as || "h6";

  return (
    <Component className={`text-white-A700 font-inter ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Heading };
