import React from "react";
import { Helmet } from "react-helmet";
import { Heading, Text, Img, Button } from "../../components";
import { Link } from "react-router-dom";

export default function HomepagePage() {
  return (
    <>
      <Helmet>
        <title>native.</title>
        <meta
          name="description"
          content="Web site created using create-react-app"
        />
      </Helmet>
      <div className="flex flex-col items-center justify-start w-full gap-[133px] bg-gradient">
        <header className="flex justify-center items-center w-full p-[13px] border-white-A700_33 border-b border-solid">
          <div className="flex flex-row justify-between items-center w-full mx-auto md:px-5 max-w-[1366px]">
            <div className="flex flex-col items-center justify-start gap-px">
              <Heading size="lg" as="h1" className="!text-[38.32px]">
                native.
              </Heading>
              <Text
                size="md"
                as="p"
                className="!text-white-A700 lowercase text-center !text-[13.94px]"
              >
                AS YOU ARE
              </Text>
            </div>
            <a href="https://lin.ee/CnDrACk">
              <Button
                shape="round"
                leftIcon={<Img src="images/img_frame.svg" alt="Frame" />}
                className="gap-2 sm:px-5 min-w-[189px]"
              >
                ADD FRIEND
              </Button>
            </a>
          </div>
        </header>
        <div className="flex flex-col items-center justify-start w-full gap-[86px]">
          <div className="flex flex-col items-center justify-start w-[58%] md:w-full gap-[23px]">
            <Heading size="2xl" as="h1" className="text-center mt-2.5">
              Welcome to native.{" "}
            </Heading>
            <Text size="lg" as="p" className="text-center !text-white-A700_cc">
              Create your custom native. card now, featuring a wide variety of
              categories of your choice.
            </Text>
          </div>
          <div className="flex flex-row md:flex-col w-full gap-[60px] md:gap-10 md:px-5 max-w-[1186px]">
            <div className="flex flex-col items-center justify-start w-[16%] md:w-full gap-7">
              <div className="flex flex-col items-center justify-start h-[161px] w-[161px] bg-white-A700_99 rounded-[80px]">
                <Img
                  src="images/img_mask_group.svg"
                  alt="career_one"
                  className="h-[161px] w-[161px] rounded-tl-[80px] rounded-tr-[80px]"
                />
              </div>
              <div className="flex flex-col items-center justify-start w-full pt-[3px] gap-[11px]">
                <a href="#">
                  <Heading as="h2">Career</Heading>
                </a>
                <Text as="p" className="text-center">
                  For the people want to find a career that is fit with your
                  native.
                </Text>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start w-[16%] md:w-full gap-7">
              <div className="flex flex-col items-center justify-start h-[161px] w-[161px] bg-white-A700_99 rounded-[80px]">
                <Img
                  src="images/img_mask_group_teal_200_01.svg"
                  alt="image"
                  className="h-[161px] w-[161px] rounded-tl-[80px] rounded-tr-[80px]"
                />
              </div>
              <div className="flex flex-col items-center justify-start w-full pt-[3px] gap-[11px]">
                <Heading as="h3">Love</Heading>
                <Text as="p" className="text-center">
                  For the people want to fall in love with your native.
                </Text>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start w-[16%] md:w-full gap-7">
              <div className="flex flex-col items-center justify-start h-[161px] w-[161px] bg-white-A700_99 rounded-[80px]">
                <Img
                  src="images/img_mask_group_blue_gray_700_01.svg"
                  alt="image"
                  className="h-[161px] w-[161px] rounded-tl-[80px] rounded-tr-[80px]"
                />
              </div>
              <div className="flex flex-col items-center justify-start w-full pt-0.5 gap-3">
                <Heading as="h4">Kids</Heading>
                <Text as="p" className="text-center">
                  For the people want to  raise your child with their native.
                </Text>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start w-[16%] md:w-full gap-7">
              <div className="flex flex-col items-center justify-start h-[161px] w-[161px] bg-white-A700_99 rounded-[80px]">
                <Img
                  src="images/img_mask_group_blue_gray_800.svg"
                  alt="image"
                  className="h-[161px] w-[161px] rounded-tl-[80px] rounded-tr-[80px]"
                />
              </div>
              <div className="flex flex-col items-center justify-start w-full pt-0.5 gap-3">
                <Heading as="h5">Life</Heading>
                <Text as="p" className="text-center">
                  For the people want to live a fit life with your native.
                </Text>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start w-[16%] md:w-full gap-7">
              <div className="flex flex-col items-center justify-start h-[161px] w-[161px] bg-white-A700_99 rounded-[80px]">
                <Img
                  src="images/img_mask_group_purple_200_01.svg"
                  alt="image"
                  className="h-[161px] w-[161px] rounded-tl-[80px] rounded-tr-[80px]"
                />
              </div>
              <div className="flex flex-col items-center justify-start w-full pt-[3px] gap-[11px]">
                <Heading as="h6">Travel</Heading>
                <Text as="p" className="text-center">
                  For the people want to travel in a way that fit with your
                  native.
                </Text>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center justify-start w-full gap-[100px]">
            <div className="flex flex-row justify-center w-full px-14 py-20 md:p-5 bg-white-A700_14">
              <div className="flex flex-col items-center justify-start w-full mt-[3px] max-w-[1142px]">
                <Heading size="xl" as="h2" className="text-center">
                  Find true yourself and nudge your life
                </Heading>
                <Text
                  size="lg"
                  as="p"
                  className="w-[81%] mt-[7px] !text-white-A700_cc text-center"
                >
                  Find true yourself by native. card and nudge your life at
                  important life stage. We are always be with you at the timing
                  of importance life decision.
                </Text>

                <div className="mt-[100px] w-full flex flex-row sm:flex-col gap-4 sm:gap-6 p-4">
                  <div className="flex flex-col justify-between w-[72%] md:w-full gap-10">
                    <div
                      style={{
                        width: 200,
                        height: 37,
                        paddingLeft: 36,
                        paddingRight: 36,
                        paddingTop: 4,
                        paddingBottom: 4,
                        background: "white",
                        borderRadius: 8,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "inline-flex",
                      }}
                    >
                      <div
                        style={{
                          color: "#C9A1D0",
                          fontSize: 18,
                          fontFamily: "Inter",
                          fontWeight: "600",
                          lineHeight: 28.8,
                          wordWrap: "break-word",
                        }}
                      >
                        native. for love
                      </div>
                    </div>

                    <div className="flex flex-col items-center justify-start p-[11px] border-white-A700_66 border-[0.5px] border-solid bg-white-A700_28 rounded-[20px]">
                      <img
                        src="images/img_whatsapp_image_2023_11_28.png"
                        alt="whatsappimage"
                        className="max-w-full h-auto md:max-w-none md:w-full md:max-h-[515px] object-cover rounded-[12px]"
                      />
                    </div>
                  </div>

                  <div className="flex flex-col justify-between w-[72%] md:w-full gap-10">
                    <div
                      style={{
                        width: 220,
                        height: 37,
                        paddingLeft: 36,
                        paddingRight: 36,
                        paddingTop: 4,
                        paddingBottom: 4,
                        background: "white",
                        borderRadius: 8,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "inline-flex",
                      }}
                    >
                      <div
                        style={{
                          color: "#C9A1D0",
                          fontSize: 18,
                          fontFamily: "Inter",
                          fontWeight: "600",
                          lineHeight: 28.8,
                          wordWrap: "break-word",
                        }}
                      >
                        native. for career
                      </div>
                    </div>
                    <div className="flex flex-col items-center justify-start p-[11px] border-white-A700_66 border-[0.5px] border-solid bg-white-A700_28 rounded-[20px]">
                      <img
                        src="images/img_whatsapp_image_2023_11_28_515x514.png"
                        alt="whatsappimage"
                        className="max-w-full h-auto md:max-w-none md:w-full md:max-h-[515px] object-cover rounded-[12px]"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center justify-start w-full gap-11 md:px-5 max-w-[915px]">
              <div className="flex flex-col items-center justify-start w-full gap-[18px] p-0.5">
                <Heading size="md" as="h3" className="mt-[5px] text-center">
                  You can generate native. card within 3 sec on LINE.
                </Heading>
                <Heading size="md" as="h4" className="text-center">
                  Kindly Add Friend
                </Heading>
              </div>
              <a href="https://lin.ee/CnDrACk">
                <Button
                  shape="round"
                  leftIcon={<Img src="images/img_frame.svg" alt="Frame" />}
                  className="gap-2 sm:px-5 min-w-[189px] sm:min-w-full"
                >
                  ADD FRIEND
                </Button>
              </a>
            </div>

            <div className="flex flex-row md:flex-col justify-between items-center w-full p-3 md:gap-10 bg-white-A700_33">
              <div className="flex flex-col items-start justify-start w-[41%] md:w-full mt-[3px] ml-[59px] gap-[3px] md:mt-0 md:ml-5">
                <div className="flex flex-row justify-start items-center gap-1">
                  <Img
                    src="images/img_group.svg"
                    alt="image"
                    className="h-[14px] w-[14px]"
                  />
                  <Text as="p" className="!text-gray-700 !font-normal">
                    2024 FUNVERTH Pvt. Ltd. All Right Reserved.
                  </Text>
                </div>
                <Text size="xs" as="p" className="!text-gray-700">
                  HQ: L-19, LGF, KALKAJI, NEWDELHI, New Delhi, Delhi, India,
                  110019
                </Text>
                <Text size="xs" as="p" className="!text-gray-700">
                  Branch: 43, WeWork Galaxy, Residency Road, Ashoka Nagar,
                  Shanthala Nagar, Bengaluru, Bengaluru Urban, Karnataka, 560025
                </Text>
              </div>
              <div className="flex flex-row justify-start mr-[59px] gap-4 md:mr-5">
                {/* <a href="/funverth.html">
                <Heading size="xs" as="h5" className="!text-gray-700">
                    About native.
                  </Heading>
                </a> */}
                <a href="/privacy_policy.html">
                  <Heading size="xs" as="h5" className="!text-gray-700">
                    Privacy Policy
                  </Heading>
                </a>
                <a href="/terms_and_conditions.html">
                  <Heading size="xs" as="h6" className="!text-gray-700">
                    Term and Conditions
                  </Heading>
                </a>
                <a href="mailto:support@be-native.me">
                  <Heading size="xs" as="p" className="!text-gray-700">
                    Contact
                  </Heading>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
